body {
  margin: 0;
  background: white;
  font-family: 'century-gothic', sans-serif;
}

a {
  color: #999999;
  text-decoration: none;
}

.ril-toolbar__item__child.ril__toolbarItemChild{
  font-size: 3vh;
}

.ril-caption-content.ril__captionContent {
  font-size: 2vh;
}

